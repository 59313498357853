<template>
  <div>
    <div class="container-fluid p-0">
      <nav>
        <div class="brand">
          <img id="cube" src="./rbc-removebg-preview.png" alt="" />
          <div id="brand-name">YCTimer</div>
        </div>
        <ul class="m-0">
          <a to="/">Home</a>
          <a to="/about-us">About Us</a>
          <a to="/contact">Contact</a>
          <!-- <a @click="handleResetExport">Reset</a> -->
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: "NavBar"
};
</script>

<style>
.brand {
  display: flex;
  align-items: center;
  /* justify-content:space-between; */

  float: left;
  height: 100%;
  padding: 12px 10px;
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  /* border-radius: 3px;
  border: 2px solid black; */
}

#cube {
  float: left;
  height: 100%;
  width: 100%;
  /* transition: transform 0.1s ease-in-out; */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cube:hover {
  animation: rotate 2s infinite linear;
  /* cursor: none; */
}

#brand-name {
  padding: 0 0 0 10px;
  font-size: 30px;
  font-weight: 900;
  color: #ff6607;
}

nav {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0;
  background-color: rgb(0, 0, 0);
  border-bottom: 2px solid black;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #ffffff;
  /* background-color: black; */
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  padding: 0 10px;
}

nav ul a {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* margin: 0 0 0 0; */
  color: #f7f7f7;
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

nav ul a:hover {
  text-decoration: none;
  background-color: #222;
  color: aliceblue;
  border-radius: 4px;
  cursor: pointer;
  /* display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 10px; */
}
</style>