<template>
  <div>
    <canvas ref="confettiCanvas" id="confetti-canvas"></canvas>
    <div id="timer" tabindex="0" @keyup.space.prevent="toggleTimer">
      <!-- <div :class="{ 'green-text': isRunning }" id="timer1"> -->
      <div :style="{ color: this.timerTextColor }" id="timer1">
        {{ formattedTime }}
        <div>
          <p class="avgBelowTimer">
            ao5 : <span id="latestAo5">{{ this.latestAo5 }}</span>
          </p>
          <p class="avgBelowTimer">
            ao12 : <span id="latestAo12">{{ this.latestAo12 }}</span>
          </p>
        </div>
      </div>

      <div id="table">
        <div id="buttonArea">
          <div>
            <p>
              Best Time:
              <b style="color: rgb(0, 225, 15)">{{ this.bestTime }}</b>
            </p>
            <p>
              Worst Time:
              <b style="color: rgb(234, 2, 2)">{{ this.worstTime }}</b>
            </p>
          </div>
          <button id="resetSession" @click="resetAndExport">Reset</button>
        </div>
        <table>
          <thead>
            <tr>
              <th id="index">Solve</th>
              <th>Time</th>
              <th>ao5</th>
              <th>ao12</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(session, index) in timeArray" :key="index">
              <td>{{ Object.keys(session)[0] }}</td>
              <td>{{ session[Object.keys(session)[0]] }}</td>
              <td v-if="index >= 4">{{ calculateAverage5(index) }}</td>
              <td v-else>-</td>
              <td v-if="index >= 11">{{ calculateAverage12(index) }}</td>
              <td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Confetti from "confetti-js";
export default {
  data() {
    return {
      isRunning: false,
      lastSpacebarPress: 0,
      holdDuration: 300,
      startTime: null,
      elapsedTime: 0,
      timeArray: [],
      timeArrayMs: [],
      sessionCount: 0,
      elapsedTime1: null,
      // elapsedTime2: null,
      sum: undefined,
      bestTime: undefined,
      bestTimeMs: undefined,
      worstTime: undefined,
      latestAo5: null,
      latestAo12: null,
      // elementId: 'elementId'
      elapsedTimeSinceLastPress: null
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.elapsedTime / 60000);
      const seconds = ((this.elapsedTime % 60000) / 1000).toFixed(3);
      if (this.elapsedTime >= 60000) {
        return `${this.padTime(minutes)}:${this.padTime(seconds)}`;
      } else {
        return `${this.padTime(seconds)}`;
      }
    },
  },
  methods: {
    toggleTimer() {
      const now = Date.now();
      this.elapsedTimeSinceLastPress = now - this.lastSpacebarPress;

      if (this.elapsedTimeSinceLastPress > this.holdDuration) {
        if (this.isRunning) {
          this.stopTimer();
        } else {
          this.startTimer(now);
        }
      }
      this.lastSpacebarPress = now;
    },
    timerTextColor() {
      if(this.elapsedTimeSinceLastPress > this.holdDuration){
        return this.timerTextColor = 'green'
      }
  },
    startConfetti(duration) {
      this.confetti = new Confetti({
        target: "confetti-canvas",
        clock: 25
      });

      this.confetti.render(); // Start the confetti animation

      // Stop the confetti after the specified duration
      setTimeout(() => {
        this.stopConfetti();
      }, duration);
    },

    stopConfetti() {
      // Check if confetti is initialized and stop the animation
      if (this.confetti) {
        this.confetti.clear();
      }
    },
    startTimer(time) {
      this.elapsedTime = 0;
      this.startTime = time;
      this.isRunning = true;
      // this.$nextTick(() => {
      //   this.highlightShortestAndLongest();
      // });
      this.updateTimer();
    },
    stopTimer() {
      if (this.isRunning) {
        this.elapsedTime = Date.now() - this.startTime;
        this.elapsedTime1 = parseFloat(this.elapsedTime); // Ensure elapsedTime1 is a number
        this.elapsedTime1 = this.formatTime(this.elapsedTime1);

        // this.$nextTick(() => {
        //   this.highlightShortestAndLongest();
        // });

        this.sessionCount++;
        this.timeArray.push({ [this.sessionCount]: this.elapsedTime1 });
        this.timeArrayMs.push({ [this.sessionCount]: this.elapsedTime });
        this.findBestTime();
        this.findWorstTime();
        this.calculateAverage5();
        // this.updateAo5AndAo12()
        if(this.elapsedTime == this.bestTimeMs){
          this.startConfetti(3000);
        }
        // console.log(this.timeArray[0]);
        this.isRunning = false;
      }
    },
    updateTimer() {
      if (this.isRunning) {
        this.elapsedTime = Date.now() - this.startTime;
        requestAnimationFrame(this.updateTimer);
      }
    },
    resetTimer() {
      this.elapsedTime = 0;

      this.timeArray = [];
      this.latestAo5 = "-";
      this.latestAo12 = "-";
      this.sessionCount = 1;
      this.isRunning = false;
    },
    resetAndExport() {
      var csvContent = "data:text/csv;charset=utf-8,";
      // Export CSV before resetting
      this.exportToCSV(csvContent);
      this.$emit("reset-export");

      // Resetting logic
      this.elapsedTime = 0;
      this.timeArray = [];
      this.sessionCount = 1;
      this.latestAo5 = "-";
      this.latestAo12 = "-";
      this.bestTime = "-";
      this.worstTime = "-";
      this.isRunning = false;
    },
    exportToCSV() {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getFullYear()}`;
      const formattedTime = `${currentDate.getHours()}-${currentDate.getMinutes()}`;

      const filename = `session-${formattedDate}-${formattedTime}.csv`;

      var csvContent = "data:text/csv;charset=utf-8,";
      const header = ["Session", "Elapsed Time"];
      csvContent += header.join(",") + "\n";

      this.timeArray.forEach((session) => {
        const sessionKey = Object.keys(session)[0];
        const sessionValue = session[sessionKey];
        const row = [sessionKey, sessionValue];
        csvContent += row.join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    formatTime(milliseconds) {
      // const minutes = Math.floor(milliseconds / 60000);
      // const seconds = ((milliseconds % 60000) / 1000).toFixed(3);
      // return `${this.padTime(minutes)}:${this.padTime(seconds)}`;

      const minutes = Math.floor(milliseconds / 60000);
      const seconds = ((milliseconds % 60000) / 1000).toFixed(3);
      if (milliseconds >= 60000) {
        return `${this.padTime(minutes)}:${this.padTime(seconds)}`;
      } else {
        return `${this.padTime(seconds)}`;
      }
    },
    padTime(value) {
      return value.toString().padStart(2, "0");
    },
    calculateAverage5(index) {
      const startIdx = index - 4;
      // console.log("startIdx-----------", startIdx);
      const endIdx = index;
      // console.log("endIdx-----------", endIdx);
      let sum = 0;

      for (let i = startIdx; i <= endIdx; i++) {
        const sessionTime =
          this.timeArrayMs[i][Object.keys(this.timeArrayMs[i])[0]];
        // console.log("sessionTime-------", sessionTime);
        sum += sessionTime;
        this.sum = sum;
        // console.log("sum--------", sum);
      }

      const average = sum / 5;
      console.log(average);
      this.latestAo5 = this.formatTime(average);
      return this.formatTime(average);
    },
    calculateAverage12(index) {
      const startIdx = index - 11;
      console.log("startIdx-----------", startIdx);
      const endIdx = index;
      console.log("endIdx-----------", endIdx);
      let sum = 0;

      for (let i = startIdx; i <= endIdx; i++) {
        const sessionTime =
          this.timeArrayMs[i][Object.keys(this.timeArrayMs[i])[0]];
        console.log("sessionTime-------", sessionTime);
        sum += sessionTime;
        this.sum = sum;
        console.log("sum--------", sum);
      }

      const average = sum / 12;
      console.log(average);
      return this.formatTime(average);
    },
    extractLatestAo5AndAo12FromTable() {
      const tableRows = document.querySelectorAll("#table tbody tr");
      if (tableRows.length > 0) {
        const latestRow = tableRows[tableRows.length - 1];
        // Assuming Ao5 is at the 2nd last column and Ao12 is at the last column
        this.latestAo5 = latestRow.children[2].innerText;
        this.latestAo12 = latestRow.children[3].innerText;
      }
    },
    findBestTime() {
      if (this.timeArrayMs.length === 0) return null;

      const times = this.timeArrayMs.map((session) => {
        return parseFloat(session[Object.keys(session)[0]]);
      });

      // console.log("findBestTime times-----------:", times)

      const bestTime = Math.min(...times);
      this.bestTimeMs = bestTime;
      // console.log("BestTime----------:", bestTime)
      this.bestTime = this.formatTime(bestTime);
    },
    findWorstTime() {
      if (this.timeArrayMs.length === 0) return null;

      const times = this.timeArrayMs.map((session) =>
        parseFloat(session[Object.keys(session)[0]])
      );

      const worstTime = Math.max(...times);
      this.worstTime = this.formatTime(worstTime);
    },
  },
};
</script>

<style scoped>
.green-text {
  color: green;
}

#timer {
  margin: 0 30px;
  padding: 0;
  display: flex;
  justify-items: center;
  justify-content: space-around;
  height: max-content;
  width: 100%;
  background-color: rgb(0, 0, 0);
  text-align: center;
  outline: none;
}

.avgBelowTimer {
  font: 30px white;
  font-family: Helvetica;
}

#table {
  /* border: 1px solid yellow; */
  color: rgb(255, 255, 255);
  justify-content: flex-end;
  margin: 0 50px;
  /* float: right; */
}
/* 
table{
  padding: 5px;
border: 1px solid yellow;
} */

tr,
td {
  color: rgb(255, 255, 255);
  outline: 1px solid rgb(255, 255, 255);
  padding: 10px;
}
th {
  color: rgb(255, 255, 255);
  outline: 1px solid rgb(255, 255, 255);
  padding: 10px;
  width: 100px;
}

#index {
  width: 30px;
}

#timer1 {
  color: aliceblue;
  font-size: 16em;
  margin-bottom: 10px;
  height: 50%;
  /* font-family: Helvetica; */
  font-family: Rubik;
}

button#resetSession {
  width: 130px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  border: 1.5px solid white;
  border-radius: 5px;
  background-color: black;
  /* padding: 10px 50px; */
}

#resetSession:hover {
  transform: scale(1.1);
  /* width: max-content;
  font-size: 3em;
  font-weight: 700;
  color: rgb(255, 255, 255);
  border: 2px solid white;
  border-radius: 10px;
  background-color: black;
  padding: 10px 50px; */
}

#buttonArea {
  display: flex;
  justify-items: auto;
  justify-content: space-between;
  padding-bottom: 10px;
  margin: 0 20px;
}

#confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999; /* Increase the z-index to ensure it's on top */
}

#timer1.green-text {
  color: green;
}
</style>
